import styled from 'styled-components';
import { ProgressBarProps } from '../interfaces';

export const ProgressContainer = styled.div`
    position: fixed;
    top: 60px;
    height: 10px !important;
    border-radius: 0px !important;
    left: 0;
    right: 0;
    z-index: 1000;
`;

export const ProgressBar = styled.div<ProgressBarProps>`
    background-color: ${props => props.backgroundColor} !important;
    background-image: none !important;
    box-shadow: none !important;
`;