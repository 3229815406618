import React, { useContext } from 'react';
import { get } from 'lodash';
import { Context as WidgetContext } from '../context/widgetContext';
import { ProgressContainer, ProgressBar } from './ProgressStyled';

interface ProgressProps {
    progress: number;
};

const Progress = ({ progress }: ProgressProps) => {
    const { style } = useContext(WidgetContext);
    return (
        <ProgressContainer className="progress">
            <ProgressBar 
                className="progress-bar"
                role="progressbar"
                // aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                style={{width: progress+'%'}}
                backgroundColor={get(style, 'progressBarBgColor', '#5cb85c')}
            />
        </ProgressContainer>
    );
};

export default Progress;