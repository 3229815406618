import React, { useState, useEffect, useContext } from 'react';
import { get } from 'lodash';
import { useForm } from "react-hook-form";
import { WidgetActions, Button, Radio } from './TemplateStyled';
import { TemplateProps, RegisterProp } from './TemplateInterface';
import { useStateMachine } from "little-state-machine";
import updateAction from "../UpdateAction";
import { Context as WidgetContext } from '../../context/widgetContext';

const RadioTemplate = ({ data, previousPage, nextPage, disablePrev }: TemplateProps) => {
    const [selectedValue, setSelectedValue] = useState(''); 
    const { action, state } = useStateMachine(updateAction);
    const { register, handleSubmit, errors, setValue } = useForm();
    const { style } = useContext(WidgetContext);

    useEffect(() => {
        if (data.fieldName in state.answers) {
            setSelectedValue(state.answers[data.fieldName]);
            setValue(data.fieldName, state.answers[data.fieldName]);
        } else {
            setSelectedValue('');
            setValue(data.fieldName, '');
        }
    }, [data]);

    const onSubmit = () => {
        action({[data.fieldName]: selectedValue });
        nextPage();
    };

    const onCheckChange = (event: React.FormEvent) => {
        setSelectedValue((event.target as HTMLInputElement).value);
    }
    const renderRadio = (fieldName: string, required: boolean, data: any[]) => {
        return data.map((item) => {
            const registerComponent: RegisterProp = {};
            if (required) {
                registerComponent['required'] = 'Required';
            }
            return (
                <Radio className={!item.enabled ? "radio default" : "radio"} key={item.value}
                    backgroundColor={get(style, 'listItemBgColor', '#3B98FA')}
                    borderColor={get(style, 'listItemBorderColor', '#3B98FA')}>
                    <label className={selectedValue === item.value ? "active btn btn-default" : "btn btn-default"}>
                        <input 
                            type="radio" id={fieldName} name={fieldName} value={item.value}
                            ref={register(registerComponent)}
                            defaultChecked={selectedValue === item.value ? true: false}
                            onChange={onCheckChange}
                        />
                        {item.value}
                    </label>
                </Radio>
            );
        });
    }

    const hasValue = () => {
        if (errors[data.fieldName]) {
            return false;
        }
        return true;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <h4>{data.question}</h4>
                {errors[data.fieldName] && <div className="alert alert-danger">{errors[data.fieldName]?.message}</div>}
                {renderRadio(data.fieldName, data.required, data.options)}
            </div>
            <WidgetActions className="widgetNav">
                <Button type="button"
                    id={data.fieldName + 'Back'}
                    className="btn btn-blocks btn-lg wizardBack"
                    backgroundColor="#ffffff" color="#333333"
                    borderColor="#ccc"
                    onClick={previousPage}
                    disabled={disablePrev}
                >Back</Button>
                <Button type="submit"
                    id={data.fieldName + 'Next'}
                    className="btn btn-blocks btn-lg wizardNext"
                    backgroundColor={get(style, 'nextButtonBgColor', '#5cb85c')}
                    color={get(style, 'nextButtonColor', '#ffffff')}
                    borderColor={get(style, 'nextButtonBorderColor', '#4cae4c')}
                    disabled={!hasValue()}
                >Next</Button>
            </WidgetActions>
      </form>
    );
};

export default RadioTemplate;