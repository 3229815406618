import React, { useContext, useEffect } from 'react';
import { get, every } from 'lodash';
import { useForm, Controller } from "react-hook-form";
import { WidgetActions, Button } from './TemplateStyled';
import { TemplateProps } from './TemplateInterface';
import { useStateMachine } from "little-state-machine";
import updateAction from "../UpdateAction";
import MaskedInput from "react-input-mask";
import ReactDOM from 'react-dom';
import { Context as WidgetContext } from '../../context/widgetContext';

interface IField {
    fieldName: string;
    label: string;
    type: string;
    mask?: string;
}

let firstNameEl: any = React.createRef();

const InputTemplate = ({ data, previousPage, nextPage, disablePrev }: TemplateProps) => {
    const { style, isRequestQuote } = useContext(WidgetContext);
    const { action } = useStateMachine(updateAction);
    const { register, handleSubmit, errors, control, setValue } = useForm();

    const onSubmit = (values: any) => {
        action(values);
        nextPage();
    };

    useEffect(() => {
        setTimeout(() => {
            if (firstNameEl) {
                const current: any = ReactDOM.findDOMNode(firstNameEl);
                current.focus();
                current.select();
            }
        }, 150);
        
        
    }, [data]);

    const renderInput = (field: IField) => {
        switch(field.type) {
            case 'text':
                if (field.fieldName === 'firstName') {
                    return (
                        <input
                            ref={(ref) => {
                                if (ref) {
                                    firstNameEl = ref;
                                }
                                
                                register(ref, {
                                    required: "Required",
                                }); 
                            }}
                            name={field.fieldName} className="form-control" placeholder={field.label} type={field.type} />
                    );
                }
                return (
                    <input
                        ref={register({
                            required: "Required",
                        })}
                        name={field.fieldName} className="form-control" placeholder={field.label} type={field.type} />
                );
            case 'email':
                return (
                    <input
                        ref={register({
                            required: "Required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        })}
                        name={field.fieldName} className="form-control" placeholder={field.label} type={field.type} />
                );
            case 'tel':
                return (
                    <Controller
                        name={field.fieldName}
                        control={control}
                        type={field.type}
                        as={<MaskedInput mask={(field.mask as string)} alwaysShowMask={false} name={field.fieldName} placeholder={field.label} type={field.type} maskChar=" " className="form-control" />}
                        rules={{ required: 'Required', pattern: { value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, message: "Invalid phone number" } }}
                        onChange={([{ value }]: any) => {
                            setTimeout(() => {
                                setValue(field.fieldName, value);
                            }, 0);
                        }}
                    />
                );
            default:
                break;
        }
    }

    const renderInputs = (data: any) => {
        const { inputs } = data;
        return inputs.map((field: any) => {
            return (
                <div className="col-md-12" key={field.fieldName}>
                    <div className="form-group contact-group" style={{ marginBottom: '15px'}}>
                        <label className="small" style={{ fontSize: '12px' }}>{field.label}</label>
                        {renderInput(field)}
                        {errors[field.fieldName] && <label className="text-danger">{errors[field.fieldName]?.message}</label>}
                    </div>
                </div>
            );
        });
    };

    const hasValue = ({ inputs }: any) => {
        return every(inputs, (field: any) => {
            if (errors[field.fieldName]) {
                return false;
            }
            return true;
        });
        
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <h4>{data.question}</h4>
                {errors[data.fieldName] && <div className="alert alert-danger">{errors[data.fieldName]?.message}</div>}
                <div className="row">
                    {renderInputs(data.options)}
                </div>
            </div>
            <WidgetActions className="widgetNav">
                <Button type="button"
                    id="contactInfoBack" 
                    className="btn btn-blocks btn-lg wizardBack"
                    backgroundColor="#ffffff" color="#333333"
                    borderColor="#ccc"
                    onClick={previousPage}
                    disabled={disablePrev}
                >Back</Button>
                <Button type="submit"
                    id="contactInfoNext"
                    className="btn btn-blocks btn-lg wizardNext"
                    backgroundColor={get(style, 'nextButtonBgColor', '#5cb85c')}
                    color={get(style, 'nextButtonColor', '#ffffff')}
                    borderColor={get(style, 'nextButtonBorderColor', '#4cae4c')}
                    disabled={!hasValue(data.options)}
                >{ isRequestQuote ? "Request" : "See Quote"}</Button>
            </WidgetActions>
      </form>
    );
};

export default InputTemplate;