import styled from 'styled-components';
import { ButtonProps, ListItemProps, QuoteProps, WidgetCTAProps } from '../../interfaces';

export const WidgetActions = styled.div`
    height: 65px;
    background-color: #eee;
    position: fixed;
    bottom: 30px;
    width: 100%;
    padding: 10px 15px;
    left: 0;
    z-index: 10;
`;

export const Button = styled.button<ButtonProps>`
    width: 49%;
    display: inline-block;
    position: relative;
    z-index: 20;
    background: ${props => props.backgroundColor} !important;
    color: ${props => props.color} !important;
    border-color: ${props => props.borderColor} !important;

    &:only-child {
        width: 100%;
        position: relative;
        z-index: 20;
    }

    &.wizardBack {
        margin-right: 5px !important;
    }

    &:focus {
        outline: 0 !important;
    }

    &:hover {
        background: ${props => props.backgroundColor} !important;
        color: ${props => props.color} !important;
        border-color: ${props => props.borderColor} !important;
    }
`;

export const Radio = styled.div<ListItemProps>`
    label.btn {
        padding: 7px 5px !important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none !important;
    }
    label {
        background: #fff !important;
        border: 1px solid #eee !important;
        margin: 15px 0 !important;
    }
    label em.default {
        position: absolute;
        right:15px;
    }
    label.btn-default.active {
        background: ${props => props.backgroundColor} !important;
        border: 1px solid ${props => props.borderColor} !important;
        color: #fff !important;
        text-shadow: none !important;
    }
    label.btn-default.active:hover {
        background: ${props => props.backgroundColor} !important;
        border: 1px solid ${props => props.borderColor} !important;
        color:#fff !important;
    }
    label.btn-default.active:focus {
        background: ${props => props.backgroundColor};
        border: 1px solid ${props => props.borderColor};
    }

    label.btn-default:hover {
        background: #f4f4f4 !important;
        border: 1px solid ${props => props.borderColor} !important;
        color:#333 !important;
    }

    input[type="radio"] {
        display: none !important;
    }
    
`;

export const Checkbox = styled.div<ListItemProps>`
    label.btn {
        padding: 7px 5px !important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none !important;
    }
    label {
        background: #fff !important;
        border: 1px solid #eee !important;
        margin: 15px 0 !important;
    }
    label em.default {
        position: absolute;
        right:15px;
    }
    label.btn-default.active {
        background: ${props => props.backgroundColor} !important;
        border: 1px solid ${props => props.borderColor} !important;
        color: #fff !important;
        text-shadow: none !important;
    }
    label.btn-default.active:hover {
        background: ${props => props.backgroundColor} !important;
        border: 1px solid ${props => props.borderColor} !important;
        color:#fff !important;
    }
    label.btn-default.active:focus {
        background: ${props => props.backgroundColor};
        border: 1px solid ${props => props.borderColor};
    }

    label.btn-default:hover {
        background: #f4f4f4 !important;
        border: 1px solid ${props => props.borderColor} !important;
        color:#333 !important;
    }

    input[type="checkbox"] {
        display: none !important;
    }
`;

export const Quote = styled.h1<QuoteProps>`
    color: ${props => props.color} !important;
    font-size: 70px !important;
`;

export const WidgetCTA = styled.a<WidgetCTAProps>`
    color: ${props => props.color} !important;
    background: ${props => props.backgroundColor} !important;
`;