import React, { useContext } from 'react';
import { get } from 'lodash';
import { Context as WidgetContext } from '../context/widgetContext';
import { WidgetPanelHeader, WidgetHeaderClose } from './PanelHeaderStyled';

interface PanelHeaderProps {
    launched: string;
    setLaunched: (value: string) => void;
};

const PanelHeader = ({ launched, setLaunched }: PanelHeaderProps) => {
    const { style, isRequestQuote } = useContext(WidgetContext);
    const onLaunched = () => {
        switch(launched) {
            case 'launched':
                setLaunched('unlaunched');
                break;
            case 'unlaunched':
                setLaunched('launched');
                break;
            default:
                break;
        }
    };
    return (
        <WidgetPanelHeader className="widgetHeader" backgroundColor={get(style, 'headerBgColor', '#038FFD')}>
            <div className="title">
                { isRequestQuote ? "Request a Quote" : "Get an Instant Quote"}
            </div>
            <WidgetHeaderClose className="levitateHeaderClose" onClick={onLaunched}><span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
            </span></WidgetHeaderClose>
        </WidgetPanelHeader>
    );
};

export default PanelHeader;