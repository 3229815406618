import styled from 'styled-components';

export const WidgetPanel = styled.div`
    position: absolute;
    top: 60px;
    width: 100%;
    bottom: 95px;
    padding: 15px;
    background-color: #fff;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 9px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(221, 223, 212, 1);;
        border-radius: 100px;
    }
`;