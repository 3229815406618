import React, { useEffect } from 'react';
import Container from './components/Container';
import { Provider as WidgetProvider } from './context/widgetContext';

declare global {
    interface Window {
      levitate: any;
      STATE_MACHINE_RESET: () => void;
    }
}

function App() {

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            if (window.STATE_MACHINE_RESET) {
                window.STATE_MACHINE_RESET();
            }
        }
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, []);

    
    return (
        <WidgetProvider>
            <Container></Container>
        </WidgetProvider>
  );
}

export default App;
