import React from 'react';
import { WidgetFooter } from './PanelFooterStyled';

const PanelFooter = () => {
    return (
        <WidgetFooter className="widgetFooter">
            <a href={"https://www.levitate.io?utm_campaign=poweredbylevitate&utm_medium=widget&utm_source="+window.location.hostname} 
                target="_blank">Powered by Levitate</a>
        </WidgetFooter>
    );
};

export default PanelFooter;