import styled from 'styled-components';
import { WidgetLaunchedProps } from '../interfaces';

export const WidgetLauncher = styled.div<WidgetLaunchedProps>`
    position: absolute;
    bottom: 0px;
    right: 15px;
    border-radius: 10px;
    background: ${props => props.backgroundColor};
    height: 50px;
    width: 200px;
    padding: 13px;
    transition-timing-function: ease-out;
    transition: 0.2s;
    background-image: ${props => `url(${props.widgetUrl}/Widget_Icon.png)`};
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 27px 30px;

    ${props => props.launched === 'launched' ? `
        width: 50px;
        transition-timing-function: ease-in;
        transition: 0.2s;
        border-radius: 50%;
        background-image: none !important;
    `: ''}

    &:hover {
        background-color: ${props => props.hoverBackgroundColor};
	    cursor: pointer;
    }

    @media only screen and (max-device-width: 667px) {
        bottom: 20px;
        ${props => props.launched === 'launched' ? `
            display: none;
        `: ''};
    }
`;

export const WidgetLauncherOpener = styled.div`
    font-size: 18px;
    color: #fff;
    line-height: 22px;
    padding-left: 26px;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    text-align: center;
`;

export const WidgetLauncherClose = styled.div`
    font-size: 40px;
    color: #fff;
    line-height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    color: #fff;

    &.show span {
        width: 50px;
        height: 50px;
        text-align: center;
        display: flex;
    }

    &.show span svg {
        margin: auto;
    }
`;