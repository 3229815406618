import React from 'react';
import Content from './Content';
import { WidgetPanel } from './PanelBodyStyled';

const PanelBody = () => {
    return (
        <WidgetPanel className="widgetBody">
            <Content />
        </WidgetPanel>
    );
};

export default PanelBody;