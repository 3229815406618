import React, { useContext } from 'react';
import { get } from 'lodash';
import { Context as WidgetContext } from '../context/widgetContext';
import { WidgetLauncher, WidgetLauncherOpener, WidgetLauncherClose } from './LauncherStyled';

interface LauncherProps {
    launched: string;
    setLaunched: (value: string) => void;
}

const Launcher = ({ launched, setLaunched }: LauncherProps) => {
    const { style, isRequestQuote } = useContext(WidgetContext);
    const onLaunched = () => {
        switch(launched) {
            case 'launched':
                setLaunched('unlaunched');
                break;
            case 'unlaunched':
                setLaunched('launched');
                break;
            default:
                break;
        }
    };

    return (
        <WidgetLauncher id="levitateLauncher" className="levitateLauncher" launched={launched} 
            onClick={onLaunched} 
            widgetUrl={process.env.REACT_APP_WIDGET_IMAGE_URL as string}
            backgroundColor={get(style, 'launcherBgColor', '#5cb85c')}
            hoverBackgroundColor={get(style, 'hoverLauncherBgColor', '#449d44')}>
            { (launched === 'unlaunched') && 
                <WidgetLauncherOpener className="levitateLauncherOpen show">
                    { isRequestQuote ? "Request a Quote" : "Get Instant Quote" }
                </WidgetLauncherOpener> 
            }
            {
                (launched === 'launched') && <WidgetLauncherClose className="leviateLauncherClose show">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                        </svg>
                    </span>
                </WidgetLauncherClose>
            }
        </WidgetLauncher>
    );
};

export default Launcher;