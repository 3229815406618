import styled from 'styled-components';

export const WidgetFooter = styled.div`
    position: absolute;
    bottom: 0px;
    height: 30px;
    background: #f8f9fb;
    width: 100%;
    padding: 5px 15px;
    font-size: 12px;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #eee;

    a {
        text-decoration: none;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        color: #333 !important;
        font-weight: normal;
        font-size: 12px;
        letter-spacing: 0.3px;
    }
`;