import React, { useState, useContext, useEffect } from 'react';
import Launcher from './Launcher';
import Panel from './Panel';
import { LevitateWidgetContainer } from './ContainerStyled';
import { Context as WidgetContext } from '../context/widgetContext';
import { isEmpty } from 'lodash';

const Container = () => {
    const [launched, setLaunched] = useState('unlaunched');
    const { questions, fetchWidgetData } = useContext(WidgetContext);

    useEffect(() => {
        fetchWidgetData();
    }, []);
    
    return (
        <div className="levitate-widget">
            {
                !isEmpty(questions) &&
                <LevitateWidgetContainer className="levitateWidgetContainer" launched={launched}>
                    <Panel launched={launched} setLaunched={setLaunched} />
                    <Launcher launched={launched} setLaunched={setLaunched} />
                </LevitateWidgetContainer>
            }
        </div>
    );
};

export default Container;