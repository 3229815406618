import styled from 'styled-components';
import { HeaderProps } from '../interfaces';

export const WidgetPanelHeader = styled.div<HeaderProps>`
    position: absolute;
    top: 0px;
    height: 60px;
    background: ${props => props.backgroundColor};
    width: 100%;
    padding: 15px;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
        color: #fff;
	    font-size: 20px;
    }

    @media only screen and (max-device-width: 667px) {
        border-radius: 0;
    }
`;

export const WidgetHeaderClose = styled.div`
    font-size: 40px;
    color: #fff;
    line-height: 0;

    @media only screen and (min-device-width: 668px) {
        display: none;
    }
`;