import React, { useState, useEffect, useContext } from 'react';
import { StateMachineProvider, createStore } from "little-state-machine";
import Progress from './Progress';
import RadioTemplate from './templates/RadioTemplate';
import MultiSelectTemplate from './templates/MultiSelectTemplate';
import InputTemplate from './templates/InputTemplate';
import CountCheckboxesTemplate from './templates/CountCheckboxesTemplate';
import CurrencyTemplate from './templates/CurrencyTemplate';
import FinalTemplate from './templates/FinalTemplate';
import { Context as WidgetContext } from '../context/widgetContext';
createStore({
    answers: {}
});

const Content = () => {
    const { questions, form } = useContext(WidgetContext);
    const [page, setPage] = useState(1);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (questions.length > 0) {
            const percent = (page / (questions.length + 1)) * 100;
            setProgress(percent);
        }
    }, []);

    const nextPage = () => {
        const percent = ((page + 1) / (questions!.length + 1)) * 100;
        setPage(page + 1);
        setProgress(percent);
    }

    const previousPage = () => {
        const percent = ((page - 1) / (questions!.length + 1)) * 100;
        setPage(page - 1);
        setProgress(percent);
    }

    const showTemplateForms = () => {
        if (questions.length > 0) {
            if (page === questions.length  + 1) {
                return <FinalTemplate form={form} questions={questions}/>;
            } else {
                const question = questions[page - 1];
                switch(question.optionsType) {
                    case "radio":
                        return (
                            <RadioTemplate disablePrev={page === 1} data={question} previousPage={previousPage} nextPage={nextPage} />
                        );
                    case "multiSelect":
                        return (
                            <MultiSelectTemplate disablePrev={page === 1} data={question} previousPage={previousPage} nextPage={nextPage} />
                        );
                    case "input":
                        return (
                            <InputTemplate disablePrev={page === 1} data={question} previousPage={previousPage} nextPage={nextPage} />
                        );
                    case "countCheckboxes":
                        return (
                            <CountCheckboxesTemplate disablePrev={page === 1} data={question} previousPage={previousPage} nextPage={nextPage} />
                        );
                    case "currency":
                        return (
                            <CurrencyTemplate disablePrev={page === 1} data={question} previousPage={previousPage} nextPage={nextPage} />
                        );
                    default:
                        return null;
                }
            }
        }
        return null;
    }
    return (
        <>
            <StateMachineProvider>
                <Progress progress={progress} />
                <div className="tab-content">
                    {showTemplateForms()}
                </div>
            </StateMachineProvider>
        </>
    );
};

export default Content;