import styled from 'styled-components';
import { LaunchedProps } from '../interfaces';

export const LevitateWidgetContainer = styled.div<LaunchedProps>`
    width: 300px!important;
    max-height: 500px!important;
    position: fixed!important;
    bottom: 50px!important;
    right: 15px!important;
    z-index: 2147483000!important;

    @media only screen and (max-device-width: 667px) {
        max-height: 100% !important;
        top: 0px !important;
        bottom: 0px !important;
        width: ${props => props.launched === 'launched' ? '100% !important' : '1% !important'};
    }
`;