import styled from 'styled-components';
import { LaunchedProps } from '../interfaces';

export const WidgetPanel = styled.div<LaunchedProps>`
    height: inherit;
    position: absolute;
    height: 500px;
    width: 100%;
    bottom: 80px;
    right: 15px;
    background: #eee;
    border-radius: 5px;
    box-shadow: 0 5px 40px rgba(0,0,0,.16)!important;
    transition-timing-function: ease-in;
    transition: 0.2s;
    animation-duration: .2s;
    display: ${props => props.launched === 'launched' ? 'block': 'none'};

    @media only screen and (max-device-width: 667px) {
        right: -15px!important;
		left: 15px!important;
		top: 0px !important; 
        height: 100% !important;
        border-radius: 0;
    }
`;