import React from 'react';
import PanelHeader from './PanelHeader';
import PanelFooter from './PanelFooter';
import PanelBody from './PanelBody';
import { WidgetPanel } from './PanelStyled';

interface PanelProps {
    launched: string;
    setLaunched: (value: string) => void;
};

const Panel = ({ launched, setLaunched }: PanelProps) => {
    return (
        <WidgetPanel className="widgetPanel animated pulse" launched={launched}>
            <PanelHeader launched={launched} setLaunched={setLaunched} />
            <PanelBody />
            <PanelFooter />
        </WidgetPanel>
    );
};

export default Panel;