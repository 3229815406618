import React, { useEffect, useRef, useContext } from 'react';
import { get } from 'lodash';
import NumberFormat from "react-number-format";
import { useForm, Controller } from "react-hook-form";
import { WidgetActions, Button } from './TemplateStyled';
import { TemplateProps } from './TemplateInterface';
import { useStateMachine } from "little-state-machine";
import updateAction from "../UpdateAction";
import ReactDOM from 'react-dom';
import { Context as WidgetContext } from '../../context/widgetContext';

const CurrencyTemplate = ({ data, previousPage, nextPage, disablePrev }: TemplateProps) => {
    const { style } = useContext(WidgetContext);
    const { action, state } = useStateMachine(updateAction);
    const { handleSubmit, errors, setValue, control } = useForm({});
    const inputEl = useRef<any>(null);

    useEffect(() => {
        if (data.fieldName in state.answers) {
            setValue(data.fieldName, state.answers[data.fieldName]);
        } else {
            setValue(data.fieldName, '0');
        }

        setTimeout(() => {
            if (inputEl && inputEl.current) {
                const current: any = ReactDOM.findDOMNode(inputEl.current);
                current.focus();
                current.select();
            }
        }, 200);
        
        
    }, [data]);

    const onSubmit = (values: any) => {
        action(values);
        nextPage();
    };

    const isMinimum = (value: string) => {
        const fieldValue = parseInt(value.replace(/,/g, ''), 10);
        if (fieldValue >= 1) return true;
        return 'Minimum is 1';
    }

    const hasValue = () => {
        if (errors[data.fieldName]) {
            return false;
        }
        return true;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <h4>{data.question}</h4>
                {errors[data.fieldName] && <div className="alert alert-danger">{errors[data.fieldName]?.message}</div>}
                <div className="form-group">
                    <label htmlFor={data.fieldName}>List Price</label>
                    <div className="input-group">
                        <span className="input-group-addon" id="basic-addon1">$</span> 
                        <Controller
                            name={data.fieldName}
                            control={control}
                            
                            as={<NumberFormat ref={inputEl} thousandSeparator={true} allowNegative={false} allowLeadingZeros={false} className="form-control" />}
                            rules={{ required: 'Required', validate: isMinimum }}
                            onChange={([{ value }]: any) => {
                                setTimeout(() => {
                                    setValue(data.fieldName, value);
                                }, 0);
                            }}
                        />
                    </div>
                </div>
            </div>
            <WidgetActions className="widgetNav">
                <Button type="button"
                    id={data.fieldName + 'Back'}
                    className="btn btn-blocks btn-lg wizardBack"
                    backgroundColor="#ffffff" color="#333333"
                    borderColor="#ccc"
                    onClick={previousPage}
                    disabled={disablePrev}
                >Back</Button>
                <Button type="submit"
                    id={data.fieldName + 'Next'}
                    className="btn btn-blocks btn-lg wizardNext"
                    backgroundColor={get(style, 'nextButtonBgColor', '#5cb85c')}
                    color={get(style, 'nextButtonColor', '#ffffff')}
                    borderColor={get(style, 'nextButtonBorderColor', '#4cae4c')}
                    disabled={!hasValue()}
                >Next</Button>
            </WidgetActions>
      </form>
    );
};

export default CurrencyTemplate;