import React, { useState, createContext } from "react";
import widgetAPI from '../api/widget';
import { WidgetProps } from '../interfaces';
import { IQuestion, IForm } from '../components/templates/TemplateInterface';

const Context = createContext({} as WidgetProps);
const Provider = ({ children }: any) => {
    const [questions, setQuestions] = useState<IQuestion []>([]);
    const [form, setForm] = useState<IForm>({
        industry: '',
        numberToCall: '',
        minimumQuote: 0,
        _id: '',
    }); 
    const [style, setStyle] = useState(null);
    const [isRequestQuote, setIsRequestQuote] = useState(false);
    const fetchWidgetData = async () => {
        if ('levitate' in window && 'app_id' in window.levitate) {
            const appId = window.levitate.app_id;
            const response = await widgetAPI.post(`/form/list`, {
                formId: appId,
                siteLoaded: window.location.href,
            });
            setForm(response.data.form);
            setQuestions(response.data.questions);
            setStyle(response.data.style);
            setIsRequestQuote(response.data.isRequestQuote);
        }
    }
    return (
        <Context.Provider value={{ questions, form, style, isRequestQuote, fetchWidgetData }}>
            {children}
        </Context.Provider>
    );
};

export { Context, Provider };
