import React, { useState, useEffect, useContext } from 'react';
import { get } from 'lodash';
import { useStateMachine } from "little-state-machine";
import updateAction from "../UpdateAction";
import { IForm, IQuestion } from './TemplateInterface';
import widgetAPI from '../../api/widget';
import { isEmpty, difference } from 'lodash';
import { WidgetActions, Quote, WidgetCTA } from './TemplateStyled';
import { Context as WidgetContext } from '../../context/widgetContext';
import LoadingSpinner from '../LoadingSpinner';
import styled from 'styled-components';

const LoadingArea = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 3;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

interface FinalTemplateProps {
    form: IForm;
    questions: IQuestion[];
}
const FinalTemplate = ({ form, questions }: FinalTemplateProps) => {
    const { style } = useContext(WidgetContext);
    const [loadingQuote, setLoadingQuote] = useState(true);
    const [quote, setQuote] = useState<number | null>(null); 
    const { state } = useStateMachine(updateAction);

    useEffect(() => {
        const postData = async () => {
            if ('levitate' in window && 'app_id' in window.levitate && 'hash' in window.levitate) {
                const answers: any = {};
                const customProperties: any = {};
                const answersArray = questions.map(q => q.fieldName).filter(q => q);

                Object.keys(state.answers).forEach(key => {
                    if (key.indexOf('customProperty_') > -1) {
                        customProperties[key.replace('customProperty_', '')] = state.answers[key];
                    } else {
                        answers[key] = state.answers[key];
                    }
                });

                const diff = difference(answersArray, Object.keys(answers));
                if (!isEmpty(diff)) {
                    diff.forEach(d => {
                        if (d) {
                            answers[d] = null;
                        }
                    })
                }
                const response = await widgetAPI.post(`/lead/create`, {
                    formId: window.levitate.app_id,
                    token: window.levitate.hash,
                    answers,
                    customProperties,
                    submittedUrl: window.location.href,
                });

                setQuote(response.data.quote);
                setLoadingQuote(false);
                if (window.STATE_MACHINE_RESET) {
                    window.STATE_MACHINE_RESET();
                }
                try {
                    localStorage.removeItem('__STATE_MACHINE__');
                } catch (err) {}
                try {
                    sessionStorage.removeItem('__STATE_MACHINE__');
                } catch (err) {}
            }
        }
        postData();

    }, []);

    return (
        <>
            <div className="top40" style={{ marginTop: '40px' }}></div>
            <div className="lead text-center">
                What {form.industry.toLowerCase()} should cost based on your request!
            </div>
            {
                (loadingQuote) &&
                <LoadingArea className="text-center loading">
                    <LoadingSpinner />
                </LoadingArea>
            }
            {
                (!loadingQuote) &&
                <Quote className="text-center quote"
                    color={get(style, 'quoteColor', '#5cb85c')}>
                    {(quote) ? '$ ' + quote.toLocaleString() : 'Call Us'}
                </Quote>
            }
            <div className="text-disclaimer">
                This Quote is intended to be a helpful estimate based on selected inputs and is subject to verification. Please call to get started.
            </div>
            <WidgetActions className="widgetNav">
                <WidgetCTA href={"tel:+1-" + form.numberToCall}
                    backgroundColor={get(style, 'nextButtonBgColor', '#5cb85c')}
                    color={get(style, 'nextButtonColor', '#ffffff')}
                    className="btn btn-block btn-lg wizardCTA">Call: {form.numberToCall}</WidgetCTA>
            </WidgetActions>
        </>
    );
};

export default FinalTemplate;